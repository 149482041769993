<template>
    <div class="view_container">
        <div id="content_main">
            <div id="conclusion_title">
                {{ languageData.stran_zakljucek_naslov }}
            </div>

            <div id="conclusion_logo">
                <img :src="getFursImage" alt="FURS Vklopi razum, zahtevaj račun!" />
            </div>
        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <div class="footer_empty_screen"></div>
            </div>
            <div class="footer_btn_item">
                <div class="footer_empty_screen"></div>
            </div>

            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_blue" @click="finnish()">{{ languageData.nadaljuj }} <i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import fursImage from '@/assets/icons/furs.png'

export default {
    data: () => ({
        title: "Zahvaljujemo se Vam za zaupanje, turistično društvo Gorje"
    }),

    computed: {
        getFursImage() {
            return fursImage
        },

        languageData() {
            return this.$store.getters.languageData
        }
    },

    methods: {
        finnish() {
            this.$store.dispatch('EMPTY_CART')
            return this.$router.push({
                name: 'Home'
            })
        }
    },

    created() {

    },

    mounted() {

    },

    unmounted() {

    }
}

</script>

<style scoped>
    #conclusion_title {
        font-size:26px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        text-align:center;
        font-weight:600;
        width:70%;
        margin-left:auto;
        margin-right:auto;
        padding-top:100px;
    }

    #conclusion_logo {
        width:80%;
        margin-left:auto;
        margin-right:auto;
        text-align:center;
        margin-top:80px;
    }

    img {
        width:45%;
    }
</style>